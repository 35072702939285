import React from 'react';
import ScrollToTop from './ScrollToTop';
import BackToTopButton from './BakctoTop';

const TermsAndConditions = () => {
    return (
        <div>
            <ScrollToTop />
            <BackToTopButton />
            <div className="limoking-page-title-wrapper header-style-2-title-wrapper">
                <div className="limoking-page-title-overlay" />
                <div className="limoking-page-title-container container">
                    <h1 className="limoking-page-title">Terms and Conditions</h1>
                    {/* <span className="limoking-page-caption">Caption placed here</span> */}
                </div>
            </div>

            <div style={{ marginTop: '20px' }} class="page">
                <div class="translations-content-container">

                    <div className="container">
                        <div className="tab-content translations-content-item en visible" id="en">
                            <div className='privacy-policy-container' style={{ padding: '20px', }}>
                                <h1>Terms and Conditions</h1>
                                <p>
                                    If for any reason you do not see your chauffeur, please call
                                    <a href="tel:+16674000092"> +1-667-400-0092</a> and press 2 to locate your chauffeur to avoid a late
                                    cancellation charge. Rates are all-inclusive estimates; however, they may not include charges
                                    for parking, tolls, and airport fees, which are passed along as incurred. Debit and credit cards
                                    will be authorized for the minimum amount estimated at the time of booking. The authorization
                                    will hold the funds until released by your bank or financial institution. 92 Transportation cannot
                                    assume responsibility for items left in vehicles.
                                </p>
                                <h2>Stops:</h2>
                                <ul>
                                    <li>Stop in Route: $20.00</li>
                                    <li>Multiple Stops: Defaults to Hourly</li>
                                </ul>
                                <h2>Wait Time Grace Periods:</h2>
                                <ul>
                                    <li>Non-Airport: 15 minutes</li>
                                    <li>Domestic Flights: 45 minutes</li>
                                    <li>International Flights: 60 minutes</li>
                                </ul>
                                <h2>Cancellation Policy:</h2>
                                <ul>
                                    <li>SEDAN: 2 hours – 100%</li>
                                    <li>SUV: 2 hours – 100%</li>
                                    <li>Luxury Sedan: 2 hours – 100%</li>
                                    <li>Executive Van: 4 hours – 100%</li>
                                </ul>
                                <p>International bookings require a 24-hour notice. Late cancellations will be charged the full fare.</p>
                                <h2>No-Show Policy:</h2>
                                <ul>
                                    <li>SEDAN: 100%</li>
                                    <li>SUV: 100%</li>
                                    <li>Luxury Sedan: 100%</li>
                                    <li>Executive Van: 100%</li>
                                    <li>Minibus: 100%</li>
                                    <li>Coach: 100%</li>
                                </ul>
                                <h2>Miscellaneous Fees:</h2>
                                <ul>
                                    <li>Baby Seat Storage: $20.00</li>
                                    <li>Airport Fees: As incurred</li>
                                </ul>
                                <h2>State Taxes & Regulatory Fees:</h2>
                                <p>Charged where applicable by law (e.g., NY and CT) – Varies</p>
                                <p>
                                    Thank you for choosing 92 Transportation, LLC. Should you need travel assistance at any time,
                                    send us an email at <a href="mailto:Info@92limo.com">Info@92limo.com</a> or please call
                                    <a href="tel:+16674000092"> +1-667-400-0092</a>.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default TermsAndConditions;
