import React from 'react'
import FooterSlider from './FooterSlider'
import ScrollToTop from './ScrollToTop'
import BackToTopButton from './BakctoTop'

const Partylikerockstar = () => {
  return (
<div className="content-wrapper">
<ScrollToTop/>
<BackToTopButton/>
  <div className="limoking-content">
    <div className="with-sidebar-wrapper">
      <section id="content-section-1" data-aos="fade-up"
                data-aos-delay="100">
        <div
          className="limoking-parallax-wrapper limoking-background-image gdlr-show-all limoking-skin-dark-skin"
          id="limoking-parallax-wrapper-1"
          data-bgspeed={0}
          style={{
            backgroundImage: 'url("upload/title-big-bg1.jpg")',
            paddingTop: 150,
            paddingBottom: 70
          }}
        >
          <div className="container">
            <div className="limoking-title-item">
              <div className="limoking-item-title-wrapper limoking-item limoking-center-icon-divider limoking-large">
                <div className="limoking-item-title-container container">
                  <div className="limoking-item-title-head-inner">
                    <h3 className="limoking-item-title limoking-skin-title limoking-skin-border">
                    Party Like a Rock Star 
                    </h3>
                  </div>
                  <div className="limoking-nav-title">
                    <div className="limoking-item-title-content-icon-divider-wrapper">
                      <div className="limoking-item-title-center-icon-divider" />
                      <div className="limoking-item-title-center-icon-divider" />
                    </div>
                  </div>
                  <div className="limoking-item-title-caption limoking-skin-info">
                  Party Like a Rock Star with 92Limo 
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
            <div className="clear" />
          </div>
        </div>
        <div className="clear" />
      </section>
      <section data-aos="fade-up"
                data-aos-delay="100" id="content-section-2">
        <div
          className="limoking-color-wrapper gdlr-show-all no-skin"
          style={{
            backgroundColor: "#ffffff",
            paddingTop: 70,
            paddingBottom: 30
          }}
        >
          <div className="container">
            <div className="six columns">
              <div className="limoking-image-frame-item limoking-item">
                <div className="limoking-frame limoking-link-type-none frame-type-none">
                  <div className="limoking-image-link-shortcode">
                    <img
                      src="upload/Pf.png"
                      alt="about-car1"
                      width={546}
                      height={321}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="six columns">
              <div className="limoking-item limoking-about-us-item limoking-plain">
                <div className="about-us-title-wrapper">
                  <h3 className="about-us-title">
                  The Ultimate VIP Experience
                  <p>
                    
                  </p>
                  </h3>
                </div>
                <div className="about-us-content-wrapper">
                  <div className="about-us-content limoking-skin-content">
                    <p>
                    Want to take your celebration to the next level? At <a href='92limo.com'><b>Limo92</b></a>, we believe 
                    everyone deserves the chance to party like a rock star. Whether it’s a 
                    special occasion or just a fun night out, our luxury limousine service 
                    ensures you ride in style, comfort, and unforgettable class.When a sleek limo pulls up, 
                    heads turn, and cameras flash—it’s your moment! With 92Limo, you’ll make a grand entrance 
                    that screams VIP. Whether you’re heading to a concert, wedding, or a glamorous event, 
                    we’ll make sure you arrive in style.
                     <br/>


                    </p>
                  </div>
                </div>
                <div className="clear" />
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div className="clear" />
      </section>
      <section data-aos="fade-up"
                data-aos-delay="100" id="content-section-3">
        <div
          className="limoking-parallax-wrapper limoking-background-image gdlr-show-all limoking-skin-dark-skin"
          id="limoking-parallax-wrapper-2"
          data-bgspeed="0.1"
          style={{
            backgroundImage: 'url("upload/skill-bg1.jpg")',
            paddingTop: 95,
            paddingBottom: 65
          }}
        >
          <div className="container">
            <div style={{width:'50%'}} className="two-fifth columns">
              <div className="limoking-skill-item-wrapper limoking-skin-content limoking-item limoking-style-1">
                <div
                  className="limoking-skill-item-title"
                  style={{ color: "#ffffff" }}
                >
                  9k
                </div>
                <div
                  className="limoking-skill-item-divider"
                  style={{ borderColor: "#ffffff" }}
                />
                <div
                  className="limoking-skill-item-caption"
                  style={{ color: "#ffffff" }}
                >
                  HAPPY CUSTOMERS
                </div>
              </div>
            </div>
            {/* <div className="three columns">
              <div className="limoking-skill-item-wrapper limoking-skin-content limoking-item limoking-style-1">
                <div
                  className="limoking-skill-item-title"
                  style={{ color: "#ffffff" }}
                >
                  12
                </div>
                <div
                  className="limoking-skill-item-divider"
                  style={{ borderColor: "#ffffff" }}
                />
                <div
                  className="limoking-skill-item-caption"
                  style={{ color: "#ffffff" }}
                >
                  LUXURY LIMOS
                </div>
              </div>
            </div> */}
            <div style={{width:'50%'}} className="two-fifth columns">
              <div className="limoking-skill-item-wrapper limoking-skin-content limoking-item limoking-style-1">
                <div
                  className="limoking-skill-item-title"
                  style={{ color: "#ffffff" }}
                >
                  900k
                </div>
                <div
                  className="limoking-skill-item-divider"
                  style={{ borderColor: "#ffffff" }}
                />
                <div
                  className="limoking-skill-item-caption"
                  style={{ color: "#ffffff" }}
                >
                  MILES
                </div>
              </div>
            </div>
            {/* <div className="three columns">
              <div className="limoking-skill-item-wrapper limoking-skin-content limoking-item limoking-style-1">
                <div
                  className="limoking-skill-item-title"
                  style={{ color: "#ffffff" }}
                >
                  30
                </div>
                <div
                  className="limoking-skill-item-divider"
                  style={{ borderColor: "#ffffff" }}
                />
                <div
                  className="limoking-skill-item-caption"
                  style={{ color: "#ffffff" }}
                >
                  QUALIFIED CHAUFFUERS
                </div>
              </div>
            </div> */}
            <div className="clear" />
          </div>
        </div>
        <div className="clear" />
      </section>
            <FooterSlider/>

    </div>
  </div>
  <div className="clear" />
</div>

  )
}

export default Partylikerockstar