import React from 'react'
import FooterSlider from './FooterSlider'
import ContactForm from './ContactUsForm'
import { TfiEmail } from 'react-icons/tfi'
import { IconContext } from 'react-icons'
import { FaPhoneAlt } from "react-icons/fa";
import ScrollToTop from './ScrollToTop'
import BackToTopButton from './BakctoTop'


const ContactUs = () => {

  function BlueLargeIcon() {
    return (
      <IconContext.Provider
        value={{ color: 'white', size: '20px' }}
      >
        <div style={{ position: 'absolute', top: '8px', left: '10px' }}>
          <TfiEmail />
        </div>
      </IconContext.Provider>
    );
  }
  function NumberIcon() {
    return (
      <IconContext.Provider
        value={{ color: 'white', size: '15px' }}
      >
        <div style={{ position: 'absolute', top: '8px', left: '10px' }}>
          <FaPhoneAlt />
        </div>
      </IconContext.Provider>
    );
  }

  return (
    <div>
      <ScrollToTop />
      <BackToTopButton />
      <div className="limoking-page-title-wrapper header-style-2-title-wrapper">
        <div className="limoking-page-title-overlay" />
        <div className="limoking-page-title-container container">
          <h1 className="limoking-page-title">How we can help you?</h1>
          {/* <span className="limoking-page-caption">Caption placed here</span> */}
        </div>
      </div>
      <div className="content-wrapper">
        <div className="limoking-content">
          <div className="above-sidebar-wrapper">
            <section id="content-section-1">
              <div
                className="limoking-full-size-wrapper gdlr-show-all"
                style={{ paddingBottom: 0, backgroundColor: "#ffffff" }}
              >
                <div
                  className="limoking-item limoking-content-item"
                  style={{ marginBottom: 0 }}
                >
                  <div className="wpgmp_map_container wpgmp-map-1" rel="map1">
                    {/* <iframe
                      style={{ width: "100%", height: 480, border: 0 }}
                      src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d19905.696399556287!2d-0.3007084089960577!3d51.417531259591925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s2334+Peterson+Street+Kingston+UK+London+H14D!5e0!3m2!1sen!2s!4v1546697086219"
                      width={600}
                      height={450}
                    /> */}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2520611.6193097113!2d-75.80276813320027!3d38.975185810705945!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c1ad9b2cc84cb77%3A0x68920133ffe284a2!2s92%20Transportation%20LLC!5e0!3m2!1sen!2s!4v1716355753473!5m2!1sen!2s"
                      width="600"
                      height="450"
                      style={{ width: "100%", height: 480, border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>

                    <div
                      style={{
                        position: "absolute",
                        width: "80%",
                        bottom: 20,
                        left: 0,
                        right: 0,
                        marginLeft: "auto",
                        marginRight: "auto",
                        color: "#000"
                      }}
                    />
                  </div>
                  <div className="clear" />
                  <div className="clear" />
                </div>
                <div className="clear" />
              </div>
            </section>
          </div>
          <div className="with-sidebar-wrapper">
            <div className="with-sidebar-container container">
              <div className="limoking-sidebar limoking-right-sidebar six columns">
                <div className="limoking-item-start-content sidebar-right-item">
                  <div
                    id="text-6"
                    className="widget widget_text limoking-item limoking-widget"
                  >
                    <img src='upload/247-service.png' style={{ marginBottom: '24px' }} height='auto' width='auto' />
                    <div
                      id="text-7"
                      className="widget widget_text limoking-item limoking-widget"
                    >
                      <h1 style={{ fontSize: '16px', color: '#d59d23', marginBottom: '10px' }} className="limoking-widget-title">92 limo Car Service</h1>
                      <div className="clear" />
                      <div className="textwidget">
                        {/* <span>VIP Car Service</span> */}
                        {/* <div>Serving all of Maryland</div> */}
                        <span>info@92Limo.com</span>
                        {/* <p>Everyday 9:00-17:00</p> */}
                      </div>
                    </div>
                    <div
                      id="text-7"
                      className="widget widget_text limoking-item limoking-widget"
                    >
                      <h1 style={{ fontSize: '16px', color: '#d59d23', marginBottom: '10px' }} className="limoking-widget-title">Office Address:</h1>
                      <div className="clear" />
                      <div className="textwidget">
                        <span>Baltimore, Maryland, USA</span>
                        {/* <div>Serving all of Metro Los Angeles</div>
                      <span>info@92Limo.com</span> */}
                        {/* <p>Everyday 9:00-17:00</p> */}
                      </div>
                    </div>
                    <div className='flexDirection' style={{ gap: '12px' }}>
                      <img className='contactUsImage' src='upload/yelp-account-lavip-car-service.png' style={{ width: '200px' }} />
                      <img className='contactUsImage' src='upload/GoogleReview.png' style={{ zIndex: '1000' }} />

                    </div>



                    {/* <h3 className="limoking-widget-title">Before Contacting Us</h3> */}
                    <div className="clear" />
                    {/* <div className="textwidget">
              92Limo is dedicated to providing the highest-quality, on-time chauffeured global ground transportation service worldwide. Our chauffeur service will exceed your expectations in excellence and quality!
              </div> */}
                  </div>
                  <div
                    id="text-7"
                    className="widget widget_text limoking-item limoking-widget"
                  >
                    {/* <h6>Toll Free:<a href="tel:18004054047" style={{ fontSize: '16px' }}> 1 (800) 405-4047</a></h6> */}
                    <h6>Emaill:<a href="tel:18004054047" style={{ fontSize: '16px' }}> info@92Limo.com</a></h6>
                  </div>
                  {/* <img src='upload/cc-and-security-icons.jpg' /> */}
                  {/* <div
                    id="text-8"
                    className="widget widget_text limoking-item limoking-widget"
                  >
                    <h3 className="limoking-widget-title">Social Media</h3>
                    <div className="clear" />
                    <div className="textwidget">
                      <a href="http://facebook.com/goodlayers" />{" "}
                      <a href="http://twitter.com/goodlayers" /> <a href="#" />{" "}
                      <a href="#" /> <a href="#" /> <a href="#" />
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="limoking-sidebar limoking-right-sidebar six columns">
                {/* <div className='contactFormMain'>
                  <h1>ok</h1>
                </div> */}
                <ContactForm />
              </div>
              <div className="clear" />
            </div>
          </div>
          <div className="below-sidebar-wrapper">
            <section id="content-section-3">
              <div
                className="limoking-parallax-wrapper limoking-background-image gdlr-show-all no-skin"
                id="limoking-parallax-wrapper-1"
                data-bgspeed="0.2"
                style={{
                  backgroundImage: 'url("upload/skill-bg1.jpg")',
                  paddingTop: 100,
                  paddingBottom: 50
                }}
              >
                <div className="container">
                  <div className="six columns">
                    <div className="limoking-box-with-icon-ux limoking-ux">
                      <div className="limoking-item limoking-box-with-icon-item pos-top type-circle">
                        <div
                          className="box-with-circle-icon"
                          style={{ backgroundColor: "#d59d23" }}
                        >
                          <BlueLargeIcon />
                        </div>
                        <h4 className="box-with-icon-title">Contact By Email</h4>
                        <div className="clear" />
                        <div className="box-with-icon-caption">
                          <p>
                            info @ 92Limo . com
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="six columns">
                    <div className="limoking-box-with-icon-ux limoking-ux">
                      <div className="limoking-item limoking-box-with-icon-item pos-top type-circle">
                        <div
                          className="box-with-circle-icon"
                          style={{ backgroundColor: "#d59d23" }}
                        >
                          <NumberIcon />
                          <br />
                        </div>
                        <h4 className="box-with-icon-title">Contact By Phone</h4>
                        <div className="clear" />
                        <div className="box-with-icon-caption">
                          <p>
                            +1 667-400-0092

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="four columns">
                    <div className="limoking-box-with-icon-ux limoking-ux">
                      <div className="limoking-item limoking-box-with-icon-item pos-top type-circle">
                        <div
                          className="box-with-circle-icon"
                          style={{ backgroundColor: "black" }}
                        >
                          <br />
                        </div>
                        <h4 className="box-with-icon-title">Come To See Us</h4>
                        <div className="clear" />
                        <div className="box-with-icon-caption">
                          <p>
                            Taking you anywhere you can imagine.
                            Not only to Airports, weddings, offices, and city touring, but anywhere you want to go.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="clear" />
                </div>
              </div>
              <div className="clear" />
            </section>
          </div>
        </div>
        <FooterSlider />
        <div className="clear" />
      </div>

    </div>
  )
}

export default ContactUs
