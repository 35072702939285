import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ContactForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const naviagte = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSuccessMessage('');
    setErrorMessage('');
    try {
      const response = await axios.post('https://contact-backend-bice.vercel.app/api/contact', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Your message has been sent successfully!');
        setFormData({
          fullName: '',
          phoneNumber: '',
          email: '',
          message: '',
        });
      } else {
        setErrorMessage(`Failed to send message: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="fullName">Full Name:</label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number:</label>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          <input type="checkbox" />
          <p
            style={{
              marginTop: '-3px',
              fontSize: 14,
            }}
          >

            By checking this box, you agree to receive text messages from 92 limo service about reservation reminders and ride updates, etc. you can reply STOP to opt-out at any time, text HELP to  <a href="tel:+16674000092"> +1-667-400-0092</a> for assistance. Message and data rates may apply. Messaging frequency may vary. please review our <span onClick={() => naviagte('/privacy-policy')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              privacy policy.
            </span>&nbsp;and terms of service.&nbsp;<span style={{color:'gray'}}>(optional)</span> 
          </p>
        </div>
        <button
          style={{
            background: isSubmitting ? '#aaa' : 'rgb(213, 157, 35)',
            padding: '15px 28px',
            color: 'white',
            cursor: isSubmitting ? 'not-allowed' : 'pointer',
          }}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Submit'}
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
