import React from 'react'
import ScrollToTop from './ScrollToTop'
import BackToTopButton from './BakctoTop'

const PrivacyPolicy = () => {
    return (
        <>
            <ScrollToTop />
            <BackToTopButton />
            <div className="limoking-page-title-wrapper header-style-2-title-wrapper">
                <div className="limoking-page-title-overlay" />
                <div className="limoking-page-title-container container">
                    <h1 className="limoking-page-title">Privacy Policy</h1>
                    {/* <span className="limoking-page-caption">Caption placed here</span> */}
                </div>
            </div>
            <div style={{ marginTop: '20px' }} class="page">
                <div  class="translations-content-container">

                    <div className="container">
                        <div className="tab-content translations-content-item en visible" id="en">

                            <div className='privacy-policy-container'>
                                <h1>Privacy Policy</h1>
                                <div>
                                    92 Transportation, LLC values its users' privacy. This Privacy Policy ("Policy") will help you
                                    understand how we collect and use personal information from those who visit our website or make use
                                    of our online facilities and services, and what we will and will not do with the information we collect.
                                    Our Policy has been designed and created to ensure those affiliated with 92 Transportation, LLC of our
                                    commitment and realization of our obligation not only to meet but to exceed most existing privacy standards.
                                </div>
                                <p>
                                    We reserve the right to make changes to this Policy at any given time. If you want to ensure that you are
                                    up to date with the latest changes, we advise you to frequently visit this page. If at any point in time
                                    92 Transportation, LLC decides to make use of any personally identifiable information on file, in a manner
                                    vastly different from that stated when this information was initially collected, the user or users shall be
                                    promptly notified by email. Users at that time shall have the option as to whether to permit the use of their
                                    information in this separate manner.
                                </p>
                                <p>
                                    This Policy applies to 92 Transportation, LLC, and it governs any and all data collection and usage by us.
                                    Through the use of our website, you are therefore consenting to the data collection procedures expressed in
                                    this Policy.
                                </p>
                                <p>
                                    Please note that this Policy does not govern the collection and use of information by companies that
                                    92 Transportation, LLC does not control, nor by individuals not employed or managed by us. If you visit
                                    a website that we mention or link to, be sure to review its privacy policy before providing the site with
                                    information.
                                </p>
                                <h2>Specifically, this Policy will inform you of the following:</h2>
                                <ul>
                                    <li>What personally identifiable information is collected from you through our website;</li>
                                    <li>Why we collect personally identifiable information and the legal basis for such collection;</li>
                                    <li>How we use the collected information and with whom it may be shared;</li>
                                    <li>What choices are available to you regarding the use of your data; and</li>
                                    <li>The security procedures in place to protect the misuse of your information.</li>
                                </ul>
                                <h2>Information We Collect</h2>
                                <p>
                                    It is always up to you whether to disclose personally identifiable information to us. However, if you elect
                                    not to do so, we reserve the right not to register you as a user or provide you with any products or services.
                                </p>
                                <p>
                                    In addition, 92 Transportation, LLC may collect non-personal anonymous demographic information, such as age,
                                    gender, household income, political affiliation, race, and religion, as well as the type of browser you are
                                    using, IP address, or type of operating system, which will assist us in providing and maintaining superior
                                    quality service.
                                </p>
                                <h2>Why We Collect Information and For How Long</h2>
                                <ul>
                                    <li>To better understand your needs and provide you with the services you have requested;</li>
                                    <li>To fulfill our legitimate interest in improving our services and products;</li>
                                    <li>
                                        To send you promotional emails containing information we think you may like when we have your consent to do so;
                                    </li>
                                    <li>
                                        To contact you to fill out surveys or participate in other types of market research, when we have your
                                        consent to do so;
                                    </li>
                                    <li>To customize our website according to your online behavior and personal preferences.</li>
                                </ul>
                                <p>The data we collect from you will be stored for no longer than necessary.</p>
                                <h2>Use of Information Collected</h2>
                                <p>
                                    92 Transportation, LLC may use personal information to assist in the operation of our website and to ensure
                                    delivery of the services you need and request. At times, we may find it necessary to use personally
                                    identifiable information as a means to keep you informed of other possible products and/or services.
                                </p>
                                <h2>Disclosure of Information</h2>
                                <p>We may not use or disclose the information provided by you except under the following circumstances:</p>
                                <ul>
                                    <li>As necessary to provide services or products you have ordered;</li>
                                    <li>In other ways described in this Policy or to which you have otherwise consented;</li>
                                    <li>As required by law, or in response to a subpoena or search warrant;</li>
                                    <li>As necessary to enforce our Terms of Service;</li>
                                    <li>As necessary to maintain, safeguard, and preserve all the rights and property of 92 Transportation, LLC.</li>
                                </ul>
                                <h2>Children Under the Age of 13</h2>
                                <p>
                                    Our website is not directed to, and does not knowingly collect personal identifiable information from,
                                    children under the age of thirteen (13). If such information is inadvertently collected, we will
                                    immediately take steps to delete it.
                                </p>
                                <h2>Unsubscribe or Opt-Out</h2>
                                <p>
                                    All users have the option to discontinue receiving communications from us by way of email or newsletters.
                                    To unsubscribe, please contact us at <a href="tel:+16674000092">+1-667-400-0092</a>.
                                </p>
                                <h2>Security</h2>
                                <p>
                                    We take precautions to protect your information. When you submit sensitive information via the website,
                                    your information is protected both online and offline.
                                </p>
                                <h2>Acceptance of Terms</h2>
                                <p>
                                    By using this website, you are hereby accepting the terms and conditions stipulated within the Privacy
                                    Policy Agreement. If you are not in agreement with our terms and conditions, then you should refrain
                                    from further use of our website.
                                </p>
                                <h2>How to Contact Us</h2>
                                <p>
                                    If you have any questions or concerns regarding the Privacy Policy Agreement related to our website,
                                    please feel free to contact us at the following:
                                </p>
                                <p>Telephone Number: <a href="tel:+16674000092">+1-667-400-0092</a></p>
                                <p>Mailing Address: <a href="mailto:info@92Limo.com">info@92Limo.com</a></p>
                                <p>Thank you for choosing 92 Transportation, LLC.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}

export default PrivacyPolicy