import { Route, Routes } from "react-router-dom";
import Home from "../Home";
import About from "../About";
import Blog from "../Blog";
import ContactUs from "../ContactUs";
import PrivacyPolicy from "../privacyPolicy";
import TermsAndConditions from "../terms-conditions";
import BookNow from "../BookNow";
import Weedinglimo from "../Weeedinglimo";
import Airporttransfers from "../Airporttransfers";
import Winetours from "../Winetours";
import Partylikerockstar from "../Partylikerockstar";
import PromGraduation from "../PromGraduation";
import Corporatetravel from "../Corporatetravel";
const Routers = () => {
	return (
		<Routes>
			<Route path="/" element={[<Home />,]} />
			<Route path="/about-us" element={<About />} />
			<Route path="/blog" element={<Blog />} />
			<Route path="/contact-us" element={<ContactUs />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/terms-conditions" element={<TermsAndConditions />} />	
			<Route path="/BookNow" element={<BookNow />} />	
			<Route path="/Weedinglimo" element={<Weedinglimo />} />
			<Route path="/Airporttransfers" element={<Airporttransfers />} />
			<Route path="/Winetours" element={<Winetours />} />
			<Route path="/Partylikerockstar" element={<Partylikerockstar />} />
			<Route path="/Corporatetravel" element={<Corporatetravel />} />
			<Route path="/PromGraduation" element={<PromGraduation />} />
		</Routes>
	);
};

export default Routers;