import React from 'react'
import Slider from 'react-slick'
import './SliderCss.css'

const SliderSection = () => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        // arrows:true,
        slidesToScroll: 1,
    };

    return (
        <div>
            <Slider  {...settings} dotsClass='FleetSlider'  slidesToShow={2} swipe={true} arrows={false}>
                <div class="item">
                    <img src="assets/img/search-car-4.png" alt="car" />
                    <div className="user-box__name" style={{ textWrap: 'nowrap' }}> Mercedes  S Class </div>
                    {/* <div className="search-result-item">
                                            <div className="search-result-item__car">
                                                <img src="assets/img/search-car-4.png" alt="car" />
                                            </div>
                                            <div className="search-result-item__info">
                                                <div className="user-box">

                                                    <div className="user-box__info">
                                                        <div className="user-box__name" style={{ textWrap: 'nowrap' }}> Mercedes  S Class </div>
                                                        <div className="user-box__rating">
                                                            <FontAwesomeIcon icon={faStar} style={{ fontSize: 10, color: '#D59D23', marginRight: '5px' }} />
                                                            <b>4.8 / 5</b>

                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="list-info">
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-1.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Max. 2 passengers in back seats</span>
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-2.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Pets are not allowed in the Car</span>
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-3.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Smoking is not allowed</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div> */}
                </div>
                <div class="item">
                    <img src="assets/img/search-car-3.png" alt="car" />
                    <div className="user-box__name">Cadillac Escalade </div>
                    {/* <div className="search-result-item">
                                            <div className="search-result-item__car">
                                                <img src="assets/img/search-car-3.png" alt="car" />
                                            </div>
                                            <div className="search-result-item__info">
                                                <div className="user-box">

                                                    <div className="user-box__info">
                                                        <div className="user-box__name">Cadillac Escalade </div>
                                                        <div className="user-box__rating">
                                                            {" "}
                                                            <FontAwesomeIcon icon={faStar} style={{ fontSize: 10, color: '#D59D23', marginRight: '5px' }} />
                                                            <b>4.8 / 5</b>

                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="list-info">
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-1.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Max. 2 passengers in back seats</span>
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-2.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Pets are not allowed in the Car</span>
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-3.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Smoking is not allowed</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div> */}

                </div>
                <div class="item">
                    <img src="assets/img/search-car-2.png" alt="car" />
                    <div className="user-box__name"> Cadillac CT6  </div>
                    {/* <div className="search-result-item">
                                            <div className="search-result-item__car">
                                                <img src="assets/img/search-car-2.png" alt="car" />
                                            </div>
                                            <div className="search-result-item__info">
                                                <div className="user-box">
                                                    <div className="user-box__info">
                                                        <div className="user-box__name"> Cadillac CT6  </div>
                                                        <div className="user-box__rating">
                                                            {" "}
                                                            <FontAwesomeIcon icon={faStar} style={{ fontSize: 10, color: '#D59D23', marginRight: '5px' }} />
                                                            <b>4.8 / 5</b>

                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="list-info">
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-1.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Max. 2 passengers in back seats</span>
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-2.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Pets are not allowed in the Car</span>
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-3.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Smoking is not allowed</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div> */}
                </div>
                <div class="item">
                    <img src="assets/img/search-car-1.png" alt="car" />
                    <div className="user-box__name">Pax upto 12 </div>
                    {/* <div className="search-result-item">
                                            <div className="search-result-item__car">
                                                <img src="assets/img/search-car-1.png" alt="car" />
                                            </div>
                                            <div className="search-result-item__info">
                                                <div className="user-box">

                                                    <div className="user-box__info">
                                                        <div className="user-box__name">Pax upto 12 </div>
                                                        <div className="user-box__rating">
                                                            {" "}
                                                            <FontAwesomeIcon icon={faStar} style={{ fontSize: 10, color: '#D59D23', marginRight: '5px' }} />
                                                            <b>4.8 / 5</b>

                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="list-info">
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-1.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Max. 2 passengers in back seats</span>
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-2.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Pets are not allowed in the Car</span>
                                                    </li>
                                                    <li>
                                                        <img
                                                            src="assets/img/driver-service-3.png"
                                                            alt="driver-service"
                                                        />
                                                        <span>Smoking is not allowed</span>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div> */}
                </div>
            </Slider>
        </div>
    )
}

export default SliderSection
