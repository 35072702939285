import React, { useEffect } from "react";

const TawkToChat = () => {
  useEffect(() => {
    // Creating the script element for Tawk.to
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://embed.tawk.to/6788125e825083258e05be1d/1ihlofgn5";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");

    // Inserting the script into the document
    const s0 = document.getElementsByTagName("script")[0];
    s0.parentNode.insertBefore(script, s0);

    // Cleanup the script when the component is unmounted
    return () => {
      if (s0.parentNode) {
        s0.parentNode.removeChild(script);
      }
    };
  }, []);

  return null;
};

export default TawkToChat;

